html {
  overflow-x: hidden;
  width: 100% !important;
}

body {
  padding-right: 0 !important;
  overflow-x: hidden;
}

@font-face {
  font-family: "Grandstander";
  src: local("Grandstander-clean"),
    url("./fonts/Grandstander-clean.ttf") format("opentype");
}

@font-face {
  font-family: "GrandstanderLight";
  src: local("Grandstander-ExtraLight"),
    url("./fonts/Grandstander-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: "PoppinsExtraBold";
  src: local("Poppins-ExtraBold"),
    url("./fonts/Poppins-ExtraBold.ttf") format("opentype");
}

@font-face {
  font-family: "PoppinsBold";
  src: local("Poppins-Bold"), url("./fonts/Poppins-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "PoppinsLight";
  src: local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("opentype");
}

/* PREVIEW */
.preview{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  object-fit: contain;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  background-color:#fef7db;
}
/* Header */

.margenHeader {
  padding-right: 0px !important;
}

.menuContainer {
  width: 90% !important;
}

.menu-drawer {
  font-family: "Grandstander" !important;
  color: #ea6043;
  font-size: 25px;
  width: 200px;
  text-decoration: none;
  font-weight: 700;
}

.menu-drawer:hover {
  color: #ea6043;
}

.navbar-toggler {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #ea6043;
  border: none;
}

.navbar-toggler-icon {
  background-image: url("../public/images/bars-solid.svg");
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(348deg)
    brightness(107%) contrast(101%);
}

.navbar-toggler:focus {
  text-decoration: none;
  box-shadow: none;
}

.btn-close {
  color: white;
  opacity: 1;
  font-size: 25px;
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(348deg)
    brightness(107%) contrast(101%);
}

.close {
  padding: 5px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #ea6043;
  border: none;
}

.offcanvas {
  background-color: #fffae6;
}

.nav-link {
  font-family: "Grandstander" !important;
  color: black;
  font-size: 25px;
}

.margen {
  padding-right: 0 !important;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  /* Styles */
  .contenedorNav {
    width: 300px;
  }
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .contenedorNav {
    max-width: 1250px;
    width: 100%;
  }
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .contenedorNav {
    max-width: 1250px;
    width: 100%;
  }
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) {
  /* Styles */
  .contenedorNav {
    max-width: 1250px;
    width: 100%;
  }
}

/*Home*/
.home {
  background-color: #fffae6;
  /* background-image:url("https://cdn.shopify.com/s/files/1/0653/2192/6874/files/fondo_header.png?v=1687552438");
  background-size:cover;
  background-position:center bottom;
  background-repeat:no-repeat; */
}

.btnCompraScroll {
  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 1000;
}

.containerHomeText {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 100px 0 0px;
  position: relative;
  overflow: hidden;
}

.dateHome {
  font-size: calc(75px + (100 - 75) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  color: #ea6043;
  font-family: "PoppinsBold" !important;
}

.logoHome,
.logoSoldout {
  max-width: 450px;
  object-fit: contain;
  position: relative;
  z-index: 1;
}
.logoSoldout {
  max-width: 1100px;
  width: 100%;
}

.descriptionHome {
  font-size: calc(16px + (50 - 16) * ((100vw - 300px) / (1900 - 300)));
  width: 70%;
  text-align: center;
  max-width: 1000px;
}

.btnShop {
  background-color: #fff102;
  color: #000;
  font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1900 - 300)));
  border: none;
  padding: 10px 20px;
  border-radius: 40px;
  font-weight: 700;
}

.nube1,
.nube2 {
  position: absolute;
}

.avioneta {
  position: absolute;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
  .nube1 {
    top: 100px;
    left: -74px;
  }
  .nube2 {
    top: 180px;
    left: 5%;
  }
  .avioneta {
    top: 20%;
    left: 78%;
    width: 200px;
  }
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .nube1 {
    top: 100px;
    left: -74px;
  }
  .nube2 {
    top: 180px;
    left: 5%;
  }
  .avioneta {
    top: 20%;
    left: 78%;
    width: 200px;
  }
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .nube1 {
    top: 100px;
    left: -74px;
  }
  .nube2 {
    top: 180px;
    left: 5%;
  }
  .avioneta {
    top: 20%;
    right: -6%;
    width: 300px;
  }
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1299px) {
  /* Styles */
  .nube1 {
    top: 100px;
    left: -74px;
  }
  .nube2 {
    top: 180px;
    left: 5%;
  }
  .avioneta {
    top: 20%;
    right: -6%;
    width: 300px;
  }
}
@media only screen and (min-width: 1300px) {
  /* Styles */
  .nube1 {
    top: 100px;
    left: -74px;
  }
  .nube2 {
    top: 180px;
    left: 5%;
  }
  .avioneta {
    top: 20%;
    right: -6%;
  }
}
@media (min-width: 2000px) {
  .descriptionHome {
    font-size: 60px !important;
  }
}

@media (max-width: 1350px) {
  .adorno1 {
    width: 50%;
    max-width: 400px;
  }

  .adorno2 {
    width: 300px;
  }
}

@media (max-width: 1200px) {
  .nube2 {
    left: 0%;
  }
}

@media (max-width: 1100px) {
  .nube2 {
    display: none;
  }
}

@media (max-width: 992px) {
  .adorno1,
  .adorno2 {
    display: none;
  }
}

@media (max-width: 768px) {
  .home {
    min-height: auto;
    background-position: left bottom;
  }

  .logoHome {
    width: 100%;
    padding: 0 10%;
  }

  .containerHomeText {
    height: auto;
    padding: 165px 0 0;
    margin-top: 0px;
  }

  .descriptionHome {
    width: 80%;
  }

  .btnCompraScroll {
    bottom: 20px;
    right: 15px;
  }

  .nube1 {
    top: 22%;
    left: 0%;
  }

  .avioneta {
    width: 150px;
    top: 22%;
    right: 0;
  }
}

@media (max-width: 576px) {
  .containerHomeText {
    padding: 155px 0 0;
  }
}

/*  Contador */
.flip-clock-wrapper {
  z-index: 0;
}

.flip:after {
  content: url("https://masterbbmundo.s3.amazonaws.com/Contador/Brillo_dado.webp");
  position: relative;
  z-index: 10;
  top: -5px;
  left: -60px;
  display: inline-block;
  width: 20px;
  height: 20px;
  transform: scale(0.1);
}

.adorno1,
.adorno2 {
  position: absolute;
  bottom: -220px;
  left: -220px;
}

.adorno1 {
  width: 600px;
}

.adorno2 {
  left: unset;
  bottom: -220px;
  right: -110px;
}

.countdownTimer {
  background-color: #fffae6;
  padding: 50px 0% 30px !important;
  margin-top: -2px;
}

.flip-clock {
  --fcc-background: transparent;
  --fcc-divider-color: transparent;
}

.fcc__label {
  font-family: PoppinsSemiBold !important;
  color: #000;
}

._2hRXr::after,
._2hRXr::before {
  width: 15px;
  height: 15px;
  margin-bottom: 6px;
  margin-top: 6px;
}

div.fcc__digit_block--day:nth-child(2),
div.fcc__digit_block--day:nth-child(3),
div.fcc__digit_block--hour:nth-child(2),
div.fcc__digit_block--hour:nth-child(3),
div.fcc__digit_block--minute:nth-child(2),
div.fcc__digit_block--minute:nth-child(3) {
  border-radius: 10px;
  font-family: "PoppinsExtraBold";
}

div.fcc__digit_block--day:nth-child(2) {
  background: #ffcc02;
}

div.fcc__digit_block--day:nth-child(3) {
  background: #ea6043;
}

div.fcc__digit_block--hour:nth-child(2) {
  background: #abcd52;
}

div.fcc__digit_block--hour:nth-child(3) {
  background: #5bc4dc;
}

div.fcc__digit_block--minute:nth-child(2) {
  background: #a170ef;
}

div.fcc__digit_block--minute:nth-child(3) {
  background: #ff76c1;
}

._SKh-V {
  border-bottom: none !important;
}

div.fcc__digit_block--day:nth-child(2)::after,
div.fcc__digit_block--day:nth-child(3)::after,
div.fcc__digit_block--hour:nth-child(2)::after,
div.fcc__digit_block--hour:nth-child(3)::after,
div.fcc__digit_block--hour:nth-child(3)::after,
div.fcc__digit_block--minute:nth-child(2)::after,
div.fcc__digit_block--minute:nth-child(3)::after {
  content: url("https://masterbbmundo.s3.amazonaws.com/Contador/Brillo_dado.webp");
  position: relative;
  z-index: 10;
}

div.fcc__separator:nth-child(6) {
  color: transparent;
  display: none;
}

/*Media queries*/
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
  div.fcc__digit_block--day:nth-child(2) {
    width: 50px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--day:nth-child(3) {
    width: 50px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--hour:nth-child(2) {
    width: 50px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--hour:nth-child(3) {
    width: 50px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--minute:nth-child(2) {
    width: 50px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--minute:nth-child(3) {
    width: 50px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--day:nth-child(2)::after,
  div.fcc__digit_block--day:nth-child(3)::after,
  div.fcc__digit_block--hour:nth-child(2)::after,
  div.fcc__digit_block--hour:nth-child(3)::after,
  div.fcc__digit_block--hour:nth-child(3)::after,
  div.fcc__digit_block--minute:nth-child(2)::after,
  div.fcc__digit_block--minute:nth-child(3)::after {
    right: 45%;
    bottom: 60%;
    display: inline-block;
    width: 20px;
    height: 20px;
    transform: scale(0.1);
  }

  ._2hRXr::after,
  ._2hRXr::before {
    width: 10px;
    height: 10px;
  }
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  div.fcc__digit_block--day:nth-child(2) {
    width: 70px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--day:nth-child(3) {
    width: 70px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--hour:nth-child(2) {
    width: 70px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--hour:nth-child(3) {
    width: 70px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--minute:nth-child(2) {
    width: 70px;
    font-size: 50px;
    height: 80px;
  }

  div.fcc__digit_block--minute:nth-child(3) {
    width: 70px;
    font-size: 50px;
    height: 80px;
  }
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) {
  /* Styles */
  div.fcc__digit_block--day:nth-child(2) {
    width: 100px;
    height: 100px;
    font-size: 80px;
  }

  div.fcc__digit_block--day:nth-child(3) {
    width: 100px;
    height: 100px;
    font-size: 80px;
  }

  div.fcc__digit_block--hour:nth-child(2) {
    width: 100px;
    height: 100px;
    font-size: 80px;
  }

  div.fcc__digit_block--hour:nth-child(3) {
    width: 100px;
    height: 100px;
    font-size: 80px;
  }

  div.fcc__digit_block--minute:nth-child(2) {
    width: 100px;
    height: 100px;
    font-size: 80px;
  }

  div.fcc__digit_block--minute:nth-child(3) {
    width: 100px;
    height: 100px;
    font-size: 80px;
  }

  div.fcc__digit_block--day:nth-child(2)::after,
  div.fcc__digit_block--day:nth-child(3)::after,
  div.fcc__digit_block--hour:nth-child(2)::after,
  div.fcc__digit_block--hour:nth-child(3)::after,
  div.fcc__digit_block--minute:nth-child(2)::after,
  div.fcc__digit_block--minute:nth-child(3)::after {
    top: -56px;
    left: -45px;
    display: inline-block;
    width: 20px;
    height: 20px;
    transform: scale(0.1);
  }
}
@media (max-width: 576px) {
}
@media (max-width: 1024px) {
  div.fcc__digit_block--day:nth-child(2)::after,
  div.fcc__digit_block--day:nth-child(3)::after,
  div.fcc__digit_block--hour:nth-child(2)::after,
  div.fcc__digit_block--hour:nth-child(3)::after,
  div.fcc__digit_block--hour:nth-child(3)::after,
  div.fcc__digit_block--minute:nth-child(2)::after,
  div.fcc__digit_block--minute:nth-child(3)::after {
    right: 45%;
    bottom: 60%;
    display: inline-block;
    width: 20px;
    height: 20px;
    transform: scale(0.1);
  }
}

/*Patrocinadores*/
.btnMap {
  background-color: #fff102 !important;
  color: #000 !important;
  font-size: calc(
    18px + (20 - 18) * ((100vw - 300px) / (1900 - 300))
  ) !important;
  border-radius: 50px !important;
}

.location {
  background-color: #ea6043;
}

.containerLocation {
  padding: 50px 12%;
  align-items: center;
}

.titleLocation {
  font-size: calc(27px + (55 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  color: #fff;
  width: 100%;
  font-family: "Grandstander" !important;
}

.subtitleLocation {
  font-size: calc(17px + (40 - 17) * ((100vw - 300px) / (1900 - 300)));
  color: #ffcc02;
  font-family: "PoppinsSemiBold" !important;
}

.addressText {
  font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 500;
  color: #fff;
  max-width: 500px;
  font-size: 20px;
}

.waze {
  max-width: 100px;
  width: 100%;
  object-fit: contain;
}

.mapa {
  max-width: 120px;
  width: 100%;
  object-fit: contain;
}

.paragraphLocation {
  font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 300;
}

.logoMuseo {
  max-width: 230px;
  margin: auto;
  display: block;
}

.decoration1 {
  width: 230px;
}

.headerLogos {
  background-color: #fdeada;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.headerLogos .logo-slider {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.headerLogos .logo-slider:hover .logos-slide {
  animation-play-state: paused;
}

.headerLogos .logos-slide {
  display: inline-block;
  animation: 10s slide infinite linear;
}

.headerLogos .logos-slide img {
  width: 130px;
  height: auto;
  margin: 0 40px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 1600px) {
  .containerLocation {
    padding: 50px 5%;
  }

  .addressText {
    max-width: 350px;
  }
}

@media (max-width: 1430px) {
  .containerLocation {
    padding: 50px 0%;
  }
}

@media (max-width: 992px) {
  .location {
    background-repeat: repeat-y;
  }

  .decoration1 {
    width: 150px;
  }
}

@media (max-width: 768px) {
  .containerLocation {
    padding: 30px 0px 40px 0px;
  }

  .subtitleLocation,
  .paragraphLocation {
    text-align: center;
  }

  .titleLocation {
    width: auto;
  }

  .decoration1 {
    margin: 30px 0 20px;
  }

  .addressText {
    margin: 0 auto 40px;
    text-align: center;
  }

  .waze {
    max-width: 85px;
  }

  .mapa {
    max-width: 100px;
  }
}

/*IconsInfo*/
.containerIconsInfo {
  background-color: #fff;
}

.gridIcons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 50px 50px 100px;
}

.iconEvent {
  max-width: 300px;
  width: 100%;
}

.quantityEvent {
  font-size: calc(27px + (70 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-family: "PoppinsExtraBold" !important;
}

.descriptionIconEvent {
  font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1900 - 300)));
  font-family: "GrandstanderLight" !important;
  line-height: 120% !important;
}

.descriptionIconEvent.bold {
  font-family: "Grandstander" !important;
}

@media (max-width: 768px) {
  .gridIcons {
    padding: 30px 20px;
    grid-gap: 10px 0px;
  }

  .iconEvent {
    width: 180px;
    max-width: 180px;
  }
}

@media (max-width: 576px) {
  .iconEvent {
    width: 210px;
    max-width: 210px;
  }
}

/*Video*/
.videoSection {
  padding: 80px 0;
}

.titleVideo {
  font-size: calc(27px + (70 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  font-family: "PoppinsExtraBold" !important;
}

/*.videoIframe {
  width: 100%!important;
  height: 800px;
  margin: auto;
  max-width: 1200px!important;
}
  */
.paragraphVideo {
  font-size: calc(15px + (27 - 15) * ((100vw - 300px) / (1900 - 300)));
  width: 90% !important;
  margin: auto;
  max-width: 1100px !important;
}

.paragraphVideo span {
  font-family: "PoppinsBold" !important;
}

.bgVideo {
  background-color: #f9eae0;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  height: 500px;
  z-index: -1;
  bottom: -250px;
}

.luces1 {
  position: absolute;
  bottom: 230px;
  left: -20px;
  width: 24vw !important;
  z-index: -1;
}

.luces2 {
  position: absolute;
  bottom: 250px;
  right: 0;
  width: 22vw !important;
  z-index: -1;
}

#masterBbmundoVideo {
  height: auto;
  position: relative;
  z-index: 2;
}

#masterBbmundoVideo video {
  width: 100%;
  height: auto;
  margin: auto;
  display: block;
}

.pauseVideo {
  display: none;
}

.iconPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000000;
  width: 100px;
  height: 100px;
  cursor: pointer;
  pointer-events: none;
}

#videoPrev {
  cursor: pointer;
}

video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

@media (max-width: 1600px) {
  .paragraphVideo {
    width: 65% !important;
  }
}

@media (max-width: 992px) {
  .paragraphVideo {
    width: 85% !important;
  }

  .luces1,
  .luces2 {
    display: none;
  }
}

@media (max-width: 767px) {
  /*
  .videoIframe {
      height: 300px;
  }
  */
  .iconPlay {
    width: 100%;
    max-width: 45px;
    height: auto;
  }

  .videoSection {
    padding: 40px 0;
  }

  .paragraphVideo {
    width: 95% !important;
  }

  .bgVideo {
    bottom: -325px;
  }
}

/*Patrocinios*/
.patrocinadores {
  background-color: #fffae6;
  padding: 80px 0 20px !important;
}

.title__patrocinadores {
  font-size: calc(27px + (70 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  color: #000;
  transition: transform 0.5s;
  font-family: PoppinsExtraBold !important;
}

.patrocinadoresHov:hover {
  transform: scale(1.1);
}

.logo {
  max-width: 230px;
  padding: 20px 0;
  width: 330px;
}

.smallLogo {
  width: 230px;
}

@media (max-width: 992px) {
  .patrocinadores {
    padding: 100px 50px !important;
  }

  .logo {
    max-width: 190px;
  }
}

@media (max-width: 576px) {
  .dateHome {
    font-size: calc(55px + (100 - 55) * ((100vw - 300px) / (1900 - 300)));
  }
  .patrocinadores {
    padding: 30px 0px !important;
  }

  .logo {
    max-width: 165px;
  }
}

/*Boletos*/
.sectionTickets {
  background-color: #fff;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 80px 0%;
  position: relative;
}

.bgTickets {
  background-color: #def3f7;
  width: 100%;
  height: 250px;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
}

.titleTickets {
  font-size: calc(27px + (70 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  font-family: "PoppinsExtraBold" !important;
}

.ticketImg {
  position: relative;
}

.sectionsTicket {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.ticket {
  width: 80%;
  margin: auto;
  display: block;
}

.ticketImg {
  max-width: 100%;
  padding: 0px;
}

.priceTickets {
  font-size: calc(15px + (32 - 15) * ((100vw - 300px) / (1900 - 300)));
  font-family: "Grandstander" !important;
}

.priceTickets.title {
  font-size: calc(19px + (43 - 19) * ((100vw - 300px) / (1900 - 300)));
  color: #121212;
}

.priceTickets.text-light {
  font-family: "Poppinsbold" !important;
}

.priceQuantityText {
  font-size: calc(20px + (55 - 20) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  color: #fff;
  font-family: "PoppinsLight" !important;
}

.priceQuantityText span {
  font-family: "PoppinsMedium" !important;
}

.stylePrice {
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: #000;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke: 2px #000;
  font-size: calc(
    15px + (50 - 15) * ((100vw - 300px) / (1900 - 300))
  ) !important;
}

.linkPrice {
  text-decoration: none;
}

.ticketPriceText {
  font-size: calc(15px + (28 - 15) * ((100vw - 300px) / (1900 - 300)));
}

.priceQuantityTextBig {
  font-size: calc(15px + (32 - 15) * ((100vw - 300px) / (1900 - 300)));
}

.disableTicket {
  cursor: not-allowed;
}

.disableText {
  opacity: 0.4;
}

.disableImg {
  opacity: 0.5;
}

p {
  line-height: normal !important;
}

.variantText2 {
  color: #fff;
}

.variantText3 {
  color: #fff;
}

.variantText4 {
  color: #fff;
}

.btnPreventa {
  display: block !important;
  margin: auto !important;
  padding: 10px 10% !important;
  border-radius: 25px !important;
  font-size: 15px !important;
  background-color: #fff !important;
  border: none !important;
  font-weight: 700 !important;
  outline: none !important;
  /* font-size: calc(16px + 0 * (100vw - 300px)) !important; */
  font-size: calc(
    15px + (32 - 15) * ((100vw - 300px) / (1900 - 300))
  ) !important;
  font-family: "PoppinsBold";
}

.btnPreventa.blue {
  color: #5bc4db !important;
}

.btnPreventa.green {
  color: #acce51 !important;
}

.btnPreventa.orange {
  color: #fabd4c !important;
}

.btnPreventa.red {
  color: #ea6043 !important;
}

.spaceDisabled {
  margin-top: 55px;
}

@media (max-width: 1370px) {
  .sectionTickets {
    padding: 130px 0%;
  }
}

@media (max-width: 992px) {
  .ticketImg {
    max-width: 100%;
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .stylePrice {
    -webkit-text-stroke: 1px #000;
  }

  .sectionTickets {
    padding: 30px 0%;
  }

  .sectionTickets {
    background-repeat: repeat;
  }

  .containerTickets {
    grid-gap: 10px 0;
  }

  .bgTickets {
    height: 35px;
  }
}

@media (max-width: 576px) {
  .ticketImg {
    max-width: 100%;
  }

  .btnPreventa {
    padding: 2px 10% !important;
  }
}

/*Especialistas*/
.especialistas {
  padding: 0px 0% !important;
  background-color: #def3f7;
  /* background-image:url("https://cdn.shopify.com/s/files/1/0653/2192/6874/files/Back_especialistas_x2_0d7df10c-8c1e-404b-8788-3290f6da9d71.png?v=1687802026"); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.circleEspecialistas {
  border-radius: 50%;
  max-width: 100%;
  height: 750px;
  width: 100%;
  object-fit: contain;
  margin: auto;
  display: block;
  position: relative;
}

.bgEspecialista {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.carousel-caption {
  position: relative !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.carousel-fade .carousel-item {
  transition: all 0.7s !important;
}

.nameEspecialista {
  font-size: calc(27px + (67 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 900;
  text-transform: capitalize;
  color: #5bc4db;
  font-family: "PoppinsExtraBold" !important;
}

.descriptionEspecialista {
  font-size: calc(17px + (20 - 17) * ((100vw - 300px) / (1900 - 300)));
  padding: 0;
  font-weight: 300;
  width: 550px;
  max-width: 550px;
  margin: auto;
  font-family: "PoppinsRegular" !important;
  color: #000;
}

.temaEspecialista {
  font-size: calc(17px + (27 - 17) * ((100vw - 300px) / (1900 - 300)));
  position: relative;
  display: inline-block;
  width: 450px;
  max-width: 450px;
  font-family: "PoppinsBold" !important;
  color: #000;
}

.temaEspecialista span {
  color: #5bc4db;
  font-size: calc(17px + 0.00625 * (100vw - 300px));
  position: relative;
  display: inline-block;
  max-width: 450px;
  font-family: "Grandstander" !important;
}

/* .btnsSlide {
position: relative;
height: 50px;
width: 300px;
margin: auto;
} */
.carousel-control-next,
.carousel-control-prev {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 130px !important;
  padding: 0 !important;
  color: black !important;
  text-align: center !important;
  border: 0 !important;
  border-radius: 30px !important;
  opacity: 1 !important;
  font-size: 15px !important;
  transition: opacity 0.15s ease !important;
  font-weight: 700;
}

.carousel-count {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 0;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: calc(15px + (17 - 15) * ((100vw - 300px) / (1900 - 300)));
}

.titleVideo {
  font-size: calc(27px + (70 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
}

.iconWeb {
  position: absolute;
  bottom: 8%;
  left: 10%;
}

.iconInstagram {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0);
}

.instaVariant {
  font-size: 70px;
  background: #fff300;
  border-radius: 35%;
  padding: 10px;
}

.iconFacebook {
  position: absolute;
  bottom: 8%;
  right: 10%;
}

.tempLogoMaster {
  background-color: #fff;
  border-radius: 50%;
  height: 400px;
  max-width: 400px;
  object-fit: contain;
  margin: auto;
  display: block;
  padding: 50px;
  position: absolute;
  top: 57%;
  left: 53%;
  transform: translate(-50%, -50%);
}

.especialistaImg {
  position: absolute;
  transform: translate(-50%, -50%);
}

.carousel-inner {
  overflow: visible !important;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 300px) and (max-device-width: 767px) {
  /* Styles */
  .carousel-control-next,
  .carousel-control-prev {
    position: relative !important;
  }
  .btnsSlide {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .btnsSlide {
    order: -1;
  }
  .especialistaImg {
    top: 67%;
    left: 54%;
    width: 100%;
    max-width: 380px;
  }
  .bgEspecialista {
    height: auto;
  }
  .circleEspecialistas {
    height: auto;
  }
  .tempLogoMaster {
    height: 250px;
    max-width: 250px;
    width: 100%;
    top: 65%;
  }
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .carousel-control-next,
  .carousel-control-prev {
    position: relative !important;
  }
  .especialistaImg {
    top: 58%;
    left: 54%;
    width: 100%;
    max-width: 240px;
    height: 240px;
  }
  .tempLogoMaster {
    height: 230px;
    max-width: 230px;
    width: 100%;
    top: 58%;
  }

  .descriptionEspecialista {
    max-width: 100%;
    width: 90%;
  }
  .bgEspecialista {
    height: auto;
  }
  .iconWeb,
  .iconInstagram,
  .iconFacebook {
    width: 22%;
    max-width: 70px;
  }

  .carousel-control-prev img,
  .carousel-control-next img {
    max-width: 50px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    font-size: 13px !important;
    width: 40% !important;
  }
  .btnsSlide {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .btnsSlide {
    order: -1;
  }
  .btnsSlide .carousel-control-prev {
    left: -65px;
  }
  .btnsSlide .carousel-control-next {
    right: -65px;
  }

  .bgEspecialista,
  .circleEspecialistas {
    height: 650px;
  }
  .tempLogoMaster,
  .especialistaImg {
    height: 230px;
    max-width: 230px;
  }
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .carousel-control-next,
  .carousel-control-prev {
    position: relative !important;
  }
  .btnsSlide {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .btnsSlide {
    order: -1;
  }
  .tempLogoMaster,
  .especialistaImg {
    height: 240px;
    max-width: 240px;
    top: 58%;
  }

  .descriptionEspecialista {
    max-width: 100%;
    width: 90%;
  }
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) {
  /* Styles */
  .btnsSlide .carousel-control-prev {
    left: -65px;
  }
  .btnsSlide .carousel-control-next {
    right: -65px;
  }
  .circleEspecialistas {
    height: 650px;
  }

  .especialistaImg {
    top: 55%;
    left: 53%;
    max-width: 400px;
  }
  .tempLogoMaster,
  .especialistaImg {
    height: 325px;
    max-width: 325px;
    top: 60%;
  }
  .bgEspecialista,
  .circleEspecialistas {
    height: 650px;
  }
  .temaEspecialista {
    width: 100%;
    max-width: 300px;
  }
  .especialistas {
    padding: 30px 0% !important;
  }

  .carousel-caption {
    padding: 50px 0 20px !important;
  }
}

/* Horarios */
.rowBgDarkBlue {
  color: white !important;
}

.containerTable {
  padding: 80px 250px !important;
}

.titleSection {
  font-size: calc(27px + (70 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  font-family: "PoppinsExtraBold" !important;
}

tr th {
  padding: 100px 20px !important;
}

tr {
  border-bottom: 10px solid #fff !important;
}

.borderRight {
  border-right: 5px solid #fff;
}

.borderBottom[aria-expanded="true"] {
  border-bottom: 1px solid transparent;
}

.borderBottom[aria-expanded="false"] {
  border-bottom: 5px solid #fff;
}

.textTableVariantDateTable[colspan="2"] {
  font-family: "PoppinsExtraBold" !important;
}

.tableContainer {
  position: relative;
  max-width: 1400px;
  margin: auto;
}

.fullRowRounded {
  border-radius: 20px;
  overflow: hidden;
}

.rowBgDarkBlue {
  background-color: #2179aa;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.rowBgLightBlue {
  background-color: #d2f9ff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.mainRow[aria-expanded="true"]:after {
  content: "-";
  font-size: 40px;
  padding-right: 10px;
  color: #fff;
  font-weight: 900;
}

.mainRow[aria-expanded="false"]:after {
  content: "+";
  font-size: 40px;
  padding-right: 10px;
  color: #fff;
  font-weight: 900;
}

.disabledRow {
  pointer-events: none;
}

.mainRow.disabledRow[aria-expanded="false"]:after {
  content: "";
  font-size: 40px;
  padding-right: 10px;
  color: #fff;
  font-weight: 900;
}

.rowWhite {
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
}

.rowBlue {
  background-color: #97e5f7;
  overflow: hidden;
  cursor: pointer;
}

.rowTransparent {
  background-color: transparent !important;
}

.rowBlack {
  background-color: #000 !important;
}

.bigFontTable {
  font-size: calc(
    15px + (22 - 15) * ((100vw - 300px) / (1800 - 300))
  ) !important;
}

.text-redMoi {
  color: #e51945;
}

.textTitleTable {
  font-size: calc(
    15px + (20 - 15) * ((100vw - 300px) / (1800 - 300))
  ) !important;
  color: #2179aa !important;
  font-family: "PoppinsMedium" !important;
}

.textTitleTable1 {
  font-size: calc(
    15px + (20 - 15) * ((100vw - 300px) / (1800 - 300))
  ) !important;
  color: white !important;
  font-family: "PoppinsMedium" !important;
}

.textTitleTable1 span {
  font-family: "PoppinsExtraBold" !important;
}

.textTitleTable span {
  font-family: "PoppinsExtraBold" !important;
}

.textTitleTable.textBeige {
  color: #eedfce;
}

.smallText {
  font-size: calc(
    13px + (13 - 13) * ((100vw - 300px) / (1800 - 300))
  ) !important;
}

.year {
  font-size: 130px;
  color: #e51945;
  width: 600px;
}

.tituloAgenda {
  font-size: 60px;
  color: #fff;
}

.imgAgenda {
  max-width: 500px !important;
  width: 100%;
}

@media (min-width: 2000px) {
  .agendaV2Text {
    font-size: 100px;
  }

  .table {
    max-width: 1800px;
    margin: auto;
  }
}

@media (max-width: 1445px) {
  .containerTable {
    padding: 100px 150px !important;
  }

  .agendaV2Text {
    left: -240px;
  }
}

@media (max-width: 1200px) {
  .textResponsiveAgenda {
    display: block;
  }

  .agendaV2Text {
    display: none;
  }

  .containerTable {
    padding: 100px 50px !important;
  }
  .carousel-inner {
    padding: 0 5%;
  }
}

@media (min-width: 1101px) {
  .table > :not(caption) > * > * {
    padding: 1.5rem 40px !important;
  }
}

@media (max-width: 1300px) {
  #horario {
    padding: 70px 7% 70px;
  }
}

@media (max-width: 1100px) {
  .table > :not(caption) > * > * {
    padding: 20px 20px 20px 20px !important;
  }

  .year {
    padding-bottom: 50px;
    padding-top: 30px;
    width: auto;
    text-align: center;
    width: 100%;
    font-size: 90px;
  }

  .tituloAgenda {
    width: auto;
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .hour {
    text-wrap: wrap;
  }
}

@media (max-width: 576px) {
  .containerTable {
    padding: 30px 10px !important;
  }

  #horario {
    padding: 60px 5% 60px;
  }

  .rowBlue {
    border-radius: 45px;
  }

  .rowBgLightBlue {
    border-radius: 45px;
  }

  .dateRow {
    padding: 20px 0 0px 20px !important;
  }
  .especialistaImg {
    max-width: 60vw;
  }
}

@media (max-width: 365px) {
  .especialistaImg {
    top: 64%;
  }
}

@media (max-width: 365px) {
  .textTitleTable {
    font-size: 12px !important;
  }
}

/*Aliados*/
.container__sponsors {
  overflow: hidden;
}

.aliados {
  background-color: #fdf2db;
  padding: 80px 100px !important;
}

.title__aliados {
  font-size: calc(27px + (70 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  font-family: "PoppinsExtraBold" !important;
}

.logoAliados {
  max-width: 200px;
  padding: 0px 0;
  width: 200px;
  transition: transform 0.5s;
}
.logoAliados.arroz {
  max-width: 190px;
  width: 300px;
}

.aliadosHov:hover {
  transform: scale(1.1);
}

.logoMotorola {
  max-width: 290px !important;
  width: 290px !important;
}

.size-2 {
  max-width: 210px;
  width: 210px;
}

.frodyLogo {
  max-width: 110px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .logoAliados {
    padding: 20px 20px !important;
  }
}

@media (max-width: 992px) {
  .aliados {
    padding: 100px 50px !important;
  }

  .logoAliados {
    max-width: 130px;
  }

  .frodyLogo {
    max-width: 100px;
  }
}

@media (max-width: 768px) {
  .logoAliados {
    max-width: 165px;
  }

  .frodyLogo {
    max-width: 100px;
  }
  .descriptionEspecialista {
    width: 100%;
    max-width: 100%;
    padding: 0 2%;
  }
  .temaEspecialista {
    width: 100%;
    max-width: 100%;
    padding: 0 2%;
  }
}

@media (max-width: 576px) {
  .aliados {
    padding: 30px 0px !important;
  }

  .logoAliados {
    max-width: 115px;
  }

  .frodyLogo {
    max-width: 100px;
  }
}

/*Faqs*/
#faqs {
  background-color: #fff;
}

.faq__main_bg_1 {
  padding: 80px 150px 100px !important;
  color: #fff;
  max-width: 1700px !important;
  margin: auto;
  text-align: center;
}

.faq__main_bg_2 {
  background-color: #d7e5e5;
  padding: 70px 5% 70px 70px !important;
  color: #ffffff;
}

.faq__title {
  color: #000;
  font-size: calc(27px + (70 - 27) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
  letter-spacing: -0.08px;
  line-height: 1;
  position: relative;
  font-family: "PoppinsExtraBold" !important;
}

.sub-faq-title {
  font-size: calc(33px + (35 - 33) * ((100vw - 300px) / (1800 - 300)));
}

.fontBold {
  font-weight: bold;
}

.number {
  width: 40px;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button[aria-expanded="false"] {
  padding: 30px 30px !important;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  border-radius: 0;
  font-weight: 600;
  font-size: calc(
    17px + (20 - 17) * ((100vw - 300px) / (1900 - 300))
  ) !important;
  font-family: "GrandStander" !important;
}

.accordion-flush .accordion-collapse {
  background-color: #fff;
  border: none;
  border-radius: 0 0 50px 50px;
  padding: 60px !important;
  transition: 0s;
}

.accordion-body {
  background-color: #fff;
  text-align: left;
  border-radius: 50px !important;
  font-size: calc(
    17px + (20 - 17) * ((100vw - 300px) / (1900 - 300))
  ) !important;
  font-family: "PoppinsRegular" !important;
}

.accordion-body span {
  font-family: "PoppinsBold" !important;
}

.accordion-flush .accordion-item .accordion-button[aria-expanded="false"] {
  border-radius: 50px !important;
  margin: 15px 0 15px;
  padding: 23px 30px !important;
  color: #fff;
}

.accordion-flush .accordion-item .accordion-button[aria-expanded="true"] {
  margin: 15px 0 -40px;
  padding: 23px 30px !important;
  border-radius: 20px !important;
}

#flush-headingOne > button[aria-expanded="false"],
#flush-headingOne > button[aria-expanded="true"] {
  background-color: #61afd3;
}

#flush-headingTwo > button[aria-expanded="false"],
#flush-headingTwo > button[aria-expanded="true"] {
  background-color: #62a681;
}

#flush-headingThree > button[aria-expanded="false"],
#flush-headingThree > button[aria-expanded="true"] {
  background-color: #f9bd4b;
}

#flush-headingFour > button[aria-expanded="false"],
#flush-headingFour > button[aria-expanded="true"] {
  background-color: #ea6043;
}

.accordion-flush .accordion-item {
  border: none;
}

.accordion-flush .accordion-item {
  background-color: transparent;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
}

.accordion-button.collapsed::after {
  background-image: url("https://masterbbmundo.s3.amazonaws.com/Faqs/angle-down-solid.svg") !important;
  filter: invert(100%) sepia(99%) saturate(0%) hue-rotate(90deg)
    brightness(109%) contrast(100%);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("https://masterbbmundo.s3.amazonaws.com/Faqs/angle-up-solid.svg") !important;
  filter: invert(100%) sepia(99%) saturate(0%) hue-rotate(90deg)
    brightness(109%) contrast(100%);
}

.accordion-button::after {
  background-image: url("https://masterbbmundo.s3.amazonaws.com/Faqs/angle-up-solid.svg") !important;
  filter: invert(100%) sepia(99%) saturate(0%) hue-rotate(90deg)
    brightness(109%) contrast(100%);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(0deg) !important;
}

.accordion-button::after {
  height: 1.5rem !important;
}

.accordion-flush,
#ContactForm {
  margin-top: 50px;
  position: relative;
  z-index: 1;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.text-black {
  color: #000000 !important;
}

.faq__title_2 {
  color: #000;
  font-size: calc(35px + (40 - 35) * ((100vw - 300px) / (1800 - 300)));
  font-weight: 700;
  letter-spacing: -0.08px;
  line-height: 1;
  position: relative;
}

.form-control {
  font-size: 1.8rem !important;
}

.field.custom:after,
.select:after,
.customer .field.custom:after,
.customer select:after,
.localization-form__select:after {
  box-shadow: none !important;
}

.field__input:focus,
.select__select:focus,
.customer .field.custom input:focus,
.customer select:focus,
.localization-form__select:focus.localization-form__select:after {
  box-shadow: none !important;
}

.field__input {
  border-radius: 4px !important;
}

.hero__button_primary {
  background: #fff;
  border: 1px solid #66d4d7;
  color: #0d8b8e;
  font-size: 14px;
  font-weight: 700;
  height: 48px;
  line-height: 16px;
  padding: 15px;
  text-align: center;
  transition: all 0.4s ease-in-out 0s;
  width: 122px;
}

.hero__button_primary:hover,
.hero__button_secondary {
  background: #0d8b8e;
  color: #fff;
  outline: none;
  border: none;
}

@media (max-width: 1200px) {
  .faq__main_bg_1 {
    padding: 100px 50px !important;
  }
}

@media (max-width: 720px) {
  .accordion-flush .accordion-item .accordion-button[aria-expanded="false"],
  .accordion-flush .accordion-item .accordion-button[aria-expanded="true"] {
    border-radius: 20px !important;
  }

  .button,
  .button-label,
  .shopify-challenge__button,
  .customer button {
    width: 75% !important;
  }

  .faq__title_2,
  .faq__title,
  .sub-faq-title {
    text-align: center;
    display: block;
  }

  .faq__main_bg_1,
  .faq__main_bg_2 {
    padding: 30px 50px !important;
  }

  .accordion-flush .accordion-item .accordion-button[aria-expanded="false"] {
    padding: 17px 13px !important;
  }

  .accordion-flush .accordion-collapse {
    padding: 60px 0px !important;
  }

  .accordion-flush .accordion-item .accordion-button[aria-expanded="true"] {
    padding: 20px 13px !important;
  }

  #faqs {
    padding: 0px 0 50px;
  }
}

@media (max-width: 576px) {
  .faq__main_bg_1,
  .faq__main_bg_2 {
    padding: 30px 10px !important;
  }
}

@media (max-width: 430px) {
  .faq__main_bg_1 {
    padding: 30px 30px 20px !important;
  }

  .faq__main_bg_2 {
    padding: 80px 30px !important;
  }
}

/*Footer*/
footer {
  font-family: "PoppinsRegular" !important;
}
.title__footer {
  font-size: calc(19px + (55 - 19) * ((100vw - 300px) / (1900 - 300)));
  font-weight: 700;
}

.textFoot:hover {
  color: #fff !important;
}

.logo {
  max-width: 190px;
  padding: 20px 0;
}

.decorationFooter1,
.decorationFooter2 {
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.decorationFooter1 {
  left: 0;
  bottom: 0;
  width: 20vw;
}

.decorationFooter2 {
  right: -58px;
  bottom: 10px;
  width: 20vw;
}

.terms {
  padding: 50px 0px;
  text-align: center;
  background-color: #ea6043;
}

.terms a {
  text-decoration: none;
  color: #fff;
  font-size: 17px;
}

@media (max-width: 1500px) {
  .decorationFooter2 {
    width: 25vw;
  }

  .decorationFooter1 {
    width: 24vw;
  }
}

@media (max-width: 768px) {
  .decorationFooter1 {
    width: 24vw;
    left: -80px;
  }

  .decorationFooter2 {
    bottom: 50px;
  }
}

@media (max-width: 992px) {
  .footer {
    padding: 100px 50px !important;
  }

  .logo {
    max-width: 190px;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 100px 0px !important;
  }

  .logo {
    max-width: 165px;
  }

  .decorationFooter2 {
    bottom: 120px;
    width: 36vw;
    right: 50%;
    transform: translateX(50%);
  }

  .decorationFooter1 {
    display: none;
  }
}

@media (max-width: 450px) {
  .decorationFooter2 {
    bottom: 156px;
  }
}

/*Terminos*/
#terms {
  font-size: calc(17px + (20 - 17) * ((100vw - 300px) / (1900 - 300)));
  margin: 100px 0;
}

#terms strong,
#terms p {
  color: #606060 !important;
}

.table {
  background: transparent !important;
}

table th,
table td {
  color: #2b2b2b;
}

table tr {
  padding: 10px 0;
}

.header-table {
  border-bottom: 4px solid #4a8fe3;
}

#terms strong {
  font-weight: bold;
}

.title {
  font-size: 38px;
}

@media (max-width: 576px) {
  .title {
    font-size: 25px;
  }
}

/*aviso*/
#privacity {
  font-size: calc(17px + (20 - 17) * ((100vw - 300px) / (1900 - 300)));
  margin: 100px 0;
}

#privacity strong,
#privacity p {
  color: #606060 !important;
}

#privacity strong {
  font-weight: bold;
}

.title {
  font-size: 38px;
}

@media (max-width: 576px) {
  .title {
    font-size: 25px;
  }
}
